.table__default {
    mat-table {
        border: 1px solid gray !important;
    }

    mat-row {
        border: 1px solid #e1e1e1;
    }

    mat-row:hover {
        background-color: #d4d1c6;
    }

    mat-header-row {
        border-bottom-width: 0 !important;
        background-color: lightgray !important;
    }

    mat-row:nth-child(odd) {
        background-color: #e5e7eb;
    }

    mat-row:nth-child(odd):hover {
        background-color: #d4d1c6;
    }

    td.mat-cell {
        padding: 20px 0;
        border: none;
    }
}